<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        Add New {{ model.singular }}
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="bg-gray-100 shadow rounded-sm"
      >
        <div class="bg-white px-6 py-4">
          <div class="flex flex-wrap w-row-offset-sm -mx-2">
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.value.title"
                :errors="error.errors.title"
                name="title"
                prop="title"
                label="Title"
                description="The title of the post."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.value.slug"
                :errors="error.errors.slug"
                name="slug"
                prop="slug"
                label="Slug"
                description="The slug which is used for the URL of the post. Auto-generated when the title is filled."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.key"
                :errors="error.errors.key"
                name="key"
                prop="key"
                label="Metadata Key"
                description="The metadata key for this post. Auto-generated when the title is filled."
                required
              />
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center w-full px-4 py-4">
          <vue-button
            text
            error
            @click="resetForm"
          >
            Reset
          </vue-button>
          <vue-button
            :icon="['fas', 'save']"
            type="submit"
            success
            @click="submitForm"
          >
            Add {{ model.singular }}
          </vue-button>
        </div>
      </vue-form>
    </template>
  </NewModelLayout>
</template>
<script>
import { map, cloneDeep } from 'lodash' // eslint-disable-line no-unused-vars
import HasSlug from '@/mixins/HasSlug'
import NewModelLayout from '@/components/layouts/NewModelLayout'

export default {
  components: {
    NewModelLayout
  },

  mixins: [
    HasSlug
  ],

  data () {
    return {
      loading: true,
      form: {},
      defaults: {
        key: '',
        type: 'page',
        value: {
          title: '',
          slug: '',
          layout: {
            cols: ['1fr'],
            rows: [],
            autoCols: '1fr',
            autoRows: '1fr'
          },
          seo: {
            keyphrase: '',
            title: '',
            description: ''
          }
        }
      },
      model: {
        singular: 'Post',
        plural: 'Posts',
        key: 'post',
        endpoint: 'metadata'
      },
      route: {
        index: 'posts.index',
        new: 'posts.new',
        single: 'posts.single'
      },
      error: {
        errors: {}
      }
    }
  },

  watch: {
    'form.value.title': function (val) {
      this.form.key = `${this.model.key}_${val.toLowerCase().replace(/-+/g, ' ').replace(/ +/g, '_').replace(/[^\w-]+/g, '')}`
      this.form.value.slug = this.getSlug(val)
    }
  },

  mounted () {
    this.initForm()
    this.getPerformanceSets()
  },

  methods: {
    initForm () {
      this.form = cloneDeep(this.defaults)
    },

    resetForm () {
      this.initForm()
    },

    submitForm () {
      this.$refs.form.validate()
      this.$api.post(this.model.endpoint, {
        ...this.form,
        ...{
          type: this.model.key,
          protected: false
        }
      })
        .then((res) => {
          this.$router.push({
            name: this.route.single,
            params: {
              model: res.data.id
            }
          })
        })
        .catch((err) => {
          this.error = err
        })
    }
  }
}
</script>
