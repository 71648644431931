<template>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-2/5 py-4">
      <slot name="title" />
    </div>
    <div class="w-full lg:w-3/5 mx-auto">
      <slot name="form" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'NewLayout'
}
</script>
